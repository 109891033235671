/* website: 3234-parkavenuevw1
 * created at 2020-11-03 11:00 by Rymma
 */

// Import all makes and organization styles files
@import "../templates/makes/volkswagen.scss";
@import "../templates/groups/parkavenue.scss";
@import "../utils/icons.scss";

.widget-sr{
    &.dealer__park-avenue-volkswagen{
        .TransactionActionDetails--wrapper .sr-button-outline-1{
            text-transform: uppercase !important;
            padding: 16px 14px !important;
            border: 2px solid !important;
        }
    }
  }